<template>
  <checkout-view
    v-if="course && order && user_profile"
    :course="course"
    :step="step"
    :isLoading="submitting"
  >
    <template #header>
      <template v-if="step === 1 || step === 3">
        <a
          v-if="course"
          :href="course.url"
          class="flex items-center text-gray-400 hover:text-gray-600 transition-colors transition-200"
        >
          <left-arrow-icon />
          <span class="pl-2.5">{{ t("back") }}</span>
        </a>
      </template>
      <template v-else>
        <a
          @click.prevent="step = 1"
          href="#"
          class="flex items-center text-gray-400 hover:text-gray-600 transition-colors transition-200"
        >
          <left-arrow-icon />
          <span class="pl-2.5">{{ t("back") }}</span>
        </a>
      </template>
    </template>
    <template #left>
      <checkout-profile-form
        v-if="step === 1"
        @loading="(v) => (submitting = v)"
        @done="step = 2"
      />
      <template v-else>
        <div class="flex-1">
          <div>
            <div class="pb-8">
              <h1 class="text-3xl sm:text-5xl font-extrabold text-custom-text">
                {{ t("payment") }}
              </h1>
              <!--            <p class="sm:text-lg font-medium mt-4 text-custom-grey-1">-->
              <!--              Fast-track Norwegian also includes all activities from the Simply-->
              <!--              online program-->
              <!--            </p>-->
            </div>
            <div v-if="order" class="pt-5 pb-5 border-t border-custom-grey-7">
              <div
                v-if="subscriptionCourseSetting"
                class="flex justify-between"
              >
                <h3 class="text-xl font-bold text-custom-grey-1">
                  {{ t("monthly_billing") }}
                </h3>
                <label class="ln-switcher relative">
                  <input
                    type="checkbox"
                    class="absolute opacity-0 pointer-events-none"
                    role="switch"
                    v-model="monthlyBilling"
                  />
                  <span
                    class="relative inline-flex flex-shrink-0 h-7 w-14 rounded-full cursor-pointer transition duration-100 bg-custom-grey-6 text-primary"
                  >
                    <i
                      class="pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-linear duration-200"
                    ></i>
                  </span>
                </label>
              </div>
            </div>
            <checkout-course-settings
              v-if="order && !monthlyBilling"
              v-model="order.booking_option_id"
              :course_settings="availableCourseSettings"
              :base_price="
                course.booking_type === 'combo'
                  ? null
                  : subscriptionCourseSetting?.amount ?? null
              "
            />
            <div>
              <form
                class="divide-y divide-custom-grey-7"
                @submit.prevent="payWithCard"
              >
                <div
                  v-if="!monthlyBilling"
                  class="pt-5 pb-5 border-t border-custom-grey-7"
                >
                  <ul
                    class="ln-radioTabs flex bg-gray-200 bg-opacity-70 rounded-lg p-2"
                  >
                    <li class="flex-1">
                      <label class="flex-1 block h-full relative">
                        <input
                          type="radio"
                          v-model="checkoutState.paymentGateway"
                          name="payment-gateway"
                          value="stripe"
                          class="absolute opacity-0 pointer-events-none"
                        />
                        <span
                          class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
                        >
                          <span
                            class="flex flex-col items-center justify-end h-full"
                          >
                            <img
                              :src="creditCardIcon"
                              width="100"
                              class="flex-1 object-contain object-center"
                              alt=""
                            />
                            <span class="mt-1 sm:mt-2 text-center">
                              {{ t("credit_card") }}
                            </span>
                          </span>
                        </span>
                      </label>
                    </li>
                    <li class="flex-1">
                      <label class="flex-1 block h-full relative">
                        <input
                          type="radio"
                          v-model="checkoutState.paymentGateway"
                          name="payment-gateway"
                          value="manual"
                          class="absolute opacity-0 pointer-events-none"
                        />
                        <span
                          class="block rounded-md lg:text-lg min-h-11 p-2 sm:p-4 cursor-pointer hover:text-gray-700 text-gray-500 transition-all duration-200 h-full text-center"
                        >
                          <span
                            class="flex flex-col items-center justify-end h-full"
                          >
                            <img
                              :src="invoiceIcon"
                              width="40"
                              class="flex-1 object-contain object-center"
                              alt=""
                            />
                            <span class="mt-1 sm:mt-2 text-center">
                              {{ t("invoice") }}
                            </span>
                          </span>
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="cards && checkoutState.paymentGateway === 'stripe'"
                  class="py-5"
                >
                  <h3 class="text-xl font-bold mb-4 text-custom-grey-1">
                    {{ t("pay_with_credit_card") }}
                  </h3>
                  <label class="block w-full" v-if="cards.length > 0">
                    <span class="text-custom-text pb-1 text-sm font-semibold">
                    </span>
                    <select
                      class="block py-2 pl-3 border border-gray-300 rounded-md cursor-pointer placeholder-gray-500 transition outline-none w-full shadow-sm min-h-12 text-custom-text bg-gray-200 bg-opacity-70 focus:bg-white focus:border-gray-500"
                      v-model="selectedCardToken"
                    >
                      <option
                        v-for="card in cards"
                        :value="card.id"
                        :key="card.id"
                      >
                        &bull;&bull;&bull;&bull; {{ card.last4 }}
                        {{ card.brand }} {{ card.exp_month }}/{{
                          card.exp_year
                        }}
                      </option>
                      <option :value="null">
                        {{ t("add_new_card") }}
                      </option>
                    </select>
                  </label>
                  <div class="py-5" v-if="selectedCardToken === null">
                    <div class="space-y-3">
                      <checkout-stripe-card
                        :stripe="stripe"
                        ref="stripeCard"
                      ></checkout-stripe-card>
                    </div>
                  </div>
                </div>
                <div
                  v-if="checkoutState.paymentGateway === 'manual'"
                  class="py-5 border-b border-custom-grey-7"
                >
                  <h3 class="text-xl font-bold mb-4 text-custom-grey-1">
                    {{ t("pay_with_invoice") }}
                  </h3>
                </div>

                <checkout-address
                  v-if="order"
                  :addressType="addressType"
                  v-model:addressID="addressID"
                  v-model:invoiceType="order.invoice_type"
                />
                <div>
                  <checkout-error
                    v-if="checkoutState.paymentError"
                    :message="checkoutState.paymentError"
                  />
                  <div class="pt-5 pb-5">
                    <div>
                      <div class="pt-4 sm:pt-7">
                        <label
                          class="inline-flex items-center js-checkbox ln-checkbox cursor-pointer text-primary"
                        >
                          <input
                            type="checkbox"
                            :value="true"
                            v-model="agreeToTerms"
                            hidden=""
                            class="absolute h-0 w-0 -left-full pointer-events-none"
                          />
                          <span>
                            <i18n-t
                              keypath="agree_terms_label"
                              tag="span"
                              class="text-custom-grey-1"
                            >
                              <template v-slot:link>
                                <a
                                  href="/terms-and-conditions"
                                  target="_blank"
                                  class="text-primary hover:text-primary-700"
                                  >{{ t("terms_and_conditions") }}</a
                                >
                              </template>
                            </i18n-t>
                          </span>
                        </label>
                      </div>
                      <checkout-action :disabled="!agreeToTerms">
                        <span
                          v-if="checkoutState.paymentGateway === 'stripe'"
                          class="leading-none"
                        >
                          {{ t("pay_with_credit_card") }}
                        </span>
                        <span
                          v-else-if="checkoutState.paymentGateway === 'manual'"
                          class="leading-none"
                        >
                          {{ t("send_invoice") }}
                        </span>
                        <span v-else class="leading-none">
                          {{ t("pay") }}
                        </span>
                        <svg class="svg-icon svg-chevron-right">
                          <use
                            xlink:href="#chevron-right"
                            width="100%"
                            height="100%"
                          ></use>
                        </svg>
                      </checkout-action>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template #right>
      <checkout-course-info
        v-if="course && order && selectedCourseSetting"
        :course="course"
        :order="order"
        :course_setting="selectedCourseSetting"
        :groups="groups"
        :base_price="
          course.booking_type === 'combo'
            ? null
            : subscriptionCourseSetting?.amount || null
        "
        v-model:selectedGroupID="order.group_id"
        v-model:startDate="order.start_date"
      />
      <checkout-coupon v-if="order" />
    </template>
  </checkout-view>
  <checkout-loader v-else />
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import {
  createCard,
  getCardsList,
  getCourseGroups,
  submitOrder,
} from "@/scripts/application/api";
import invoiceIcon from "../assets/invoice-icon.png";
import creditCardIcon from "../assets/credits-icon.png";
import { useRoute, useRouter } from "vue-router";
import type { Stripe, StripeConstructorOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useI18n } from "vue-i18n";
import type { AxiosError } from "axios";
import axios from "axios";
import { CheckoutView } from "@/scripts/application/views";
import {
  CheckoutAction,
  CheckoutAddress,
  CheckoutCoupon,
  CheckoutCourseInfo,
  CheckoutCourseSettings,
  CheckoutError,
  CheckoutProfileForm,
  CheckoutStripeCard,
  LeftArrowIcon,
} from "@/scripts/application/components";
import type {
  ICard,
  ICourseSetting,
  IOrder,
} from "@/scripts/application/types";
import { groups } from "@/scripts/application/stores/checkout";

import {
  useUserProfile,
  useCourse,
  useOrder,
  useAddresses,
  usePaymentGateways,
} from "@/scripts/application/composables";
import CheckoutLoader from "@/scripts/application/components/CheckoutLoader.vue";

type tCheckoutState = {
  step: number;
  loaded: boolean;
  paymentGateway: "stripe" | "manual";
  paymentError: string | null;
};

const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();
const { saveAddress } = useAddresses();
const { course, fetchCourse } = useCourse();
const { order, fetchOrder, updateOrder: updateCourseOrder } = useOrder();
const { fetchPaymentGateways } = usePaymentGateways();
const { user_profile, fetchUserProfile } = useUserProfile();

const checkoutState = reactive<tCheckoutState>({
  step: 1,
  loaded: false,
  paymentError: null,
  paymentGateway: "stripe",
});
const stripe = ref<Stripe | null>();
const step = computed({
  get() {
    return route.query.step ? parseInt(route.query.step as string) : 1;
  },
  set(value) {
    router.push({ query: { step: value } });
  },
});

const submitting = ref<boolean>(false);

const stripeCard = ref<InstanceType<typeof CheckoutStripeCard>>();
const agreeToTerms = ref<boolean>(false);
const cards = ref<ICard[]>();
const individualAddressID = ref<number | null>(null);
const companyAddressID = ref<number | null>(null);

const selectedCourseSetting = computed<ICourseSetting | null>(() => {
  if (!course.value) return null;

  return (
    course.value?.course_settings.find(
      (cs) => cs.id === order.value?.booking_option_id
    ) ?? null
  );
});
const monthlyBilling = computed<boolean>({
  get() {
    return selectedCourseSetting.value?.kind === "subscription";
  },
  set(value) {
    if (!order.value) return;
    if (value) {
      if (selectedCourseSetting.value?.kind !== "subscription") {
        const id = subscriptionCourseSetting.value?.id;
        if (id) order.value.booking_option_id = id;
      }
      return;
    }
    const id = course.value?.course_settings.find(
      (cs) => cs.kind !== "subscription"
    )?.id;
    if (id) order.value.booking_option_id = id;
  },
});
const availableCourseSettings = computed(() => {
  if (!course.value) return [];
  if (monthlyBilling.value)
    return course.value.course_settings.filter(
      (cs) => cs.kind === "subscription"
    );
  else if (course.value.variety === "personal")
    return course.value.course_settings
      .filter((cs) => cs.kind === "private_group")
      .sort((a, b) => a.amount - b.amount);
  else
    return course.value.course_settings
      .filter((cs) => ["licence", "private_group"].includes(cs.kind))
      .sort((a, b) => a.amount - b.amount);
});
const subscriptionCourseSetting = computed(() => {
  if (!course.value) return null;
  return course.value.course_settings.find((cs) => cs.kind === "subscription");
});
// const activeAddress = computed<IAddress | null>({
//   get() {
//     return (
//       availableAddresses.value.find((a) => a.id === order.address_id) || null
//     );
//   },
//   set(value) {
//     order.address_id = value?.id || null;
//   },
// });
const addressType = computed<"basic" | "individual" | "company">({
  get() {
    if (checkoutState.paymentGateway === "stripe") return "basic";
    if (order.value?.invoice_type === "individual") return "individual";
    return "company";
  },
  set(value) {
    if (order.value)
      order.value.invoice_type = value === "company" ? "company" : "individual";
  },
});

const addressID = computed<number | null>({
  get() {
    if (addressType.value === "company") {
      return companyAddressID.value;
    } else {
      return individualAddressID.value;
    }
  },
  set(value) {
    if (addressType.value === "company") companyAddressID.value = value;
    else individualAddressID.value = value;
  },
});

watch(
  () => order.value?.address_id,
  (newV) => {
    if (newV !== undefined && addressID.value !== newV) addressID.value = newV;
  }
);

watch(addressType, (v) => {
  if (order.value && v === "basic") order.value.invoice_type = "individual";
});

watch(addressID, (v, old) => {
  console.log(`addressID ${old} => ${v}`);
  if (order.value) order.value.address_id = v;
});

const selectedCardToken = computed<string | null | undefined>({
  get() {
    if (order.value?.card_token) return order.value.card_token;
    if (!cards.value) return null;
    // initialize with existing card
    if (order.value?.card_token === undefined && cards.value.length > 0)
      return cards.value[0].id;

    return order.value?.card_token ?? null;
  },
  set(value) {
    if (value === order.value?.card_token) return;

    console.log(`${order.value?.card_token} => ${value}`);
    if (order.value) order.value.card_token = value;
  },
});

async function updateOrder(newData: Partial<IOrder>) {
  if (!checkoutState.loaded) return;
  await updateCourseOrder(newData);
}

watch(
  () => order.value?.group_id,
  (newData, oldData) => {
    if (oldData === undefined) return;
    if (newData === oldData) return;
    if (!checkoutState.loaded) return;
    console.log(`group_id ${oldData} => ${newData}`);
    updateOrder({ group_id: newData });
  }
);

watch(
  () => order.value?.coupon_code,
  (newData, oldData) => {
    if (oldData === undefined) return;
    if (newData === oldData) return;
    if (!checkoutState.loaded) return;
    console.log(`coupon_code ${oldData} => ${newData}`);
    updateOrder({ coupon_code: newData });
  }
);

watch(
  () => order.value?.booking_option_id,
  (newData, oldData) => {
    if (oldData === undefined) return;
    if (newData === oldData) return;
    console.log(`booking_option_id ${oldData} => ${newData}`);
    if (!checkoutState.loaded) return;
    updateOrder({ booking_option_id: newData });
  }
);
watch(
  () => order.value?.start_date,
  (newData, oldData) => {
    if (oldData === undefined) return;
    if (newData === oldData) return;
    if (!checkoutState.loaded) return;
    console.log(`start_date ${oldData} => ${newData}`);
    updateOrder({ start_date: newData });
  }
);
watch(subscriptionCourseSetting, (value) => {
  if (!value) monthlyBilling.value = false;
});
watch(monthlyBilling, (value) => {
  if (value) checkoutState.paymentGateway = "stripe";
});

onMounted(() => {
  fetchOrder();
  fetchCourse();
  fetchPaymentGateways();
  fetchUserProfile();
  Promise.all([
    getCourseGroups(route.params.course_id as string),
    getCardsList(),
  ])
    .then(([remoteGroups, cardsList]) => {
      groups.value = remoteGroups;
      cards.value = cardsList;
    })
    .then(() => {
      checkoutState.loaded = true;
    });
});

async function payWithCard() {
  checkoutState.paymentError = null;
  if (!agreeToTerms.value) {
    checkoutState.paymentError = "Please agree to the terms and conditions";
    return;
  }
  if (!user_profile.value) {
    checkoutState.paymentError = "Please login to continue";
    return;
  }
  if (submitting.value) return;
  submitting.value = true;
  if (!selectedCardToken.value && checkoutState.paymentGateway === "stripe") {
    const result = await stripeCard.value?.getToken();
    if (result.error) {
      submitting.value = false;
      checkoutState.paymentError = result.error.message;
      return;
    }
    try {
      const newCard = await createCard(result.token.id);
      cards.value = [...(cards.value || []), newCard];
      selectedCardToken.value = newCard.id;
    } catch (err) {
      submitting.value = false;
      if (axios.isAxiosError(err) && err.response?.status === 422) {
        const error = err as AxiosError<{
          error: string;
        }>;
        checkoutState.paymentError = error.response?.data?.error || null;
      }
      return;
    }
    getCardsList().then((cardsList) => {
      cards.value = cardsList;
    });
  }

  try {
    const address = await saveAddress();
    if (order.value && address) await updateOrder({ address_id: address.id });
  } catch (e) {
    submitting.value = false;
    checkoutState.paymentError = "Error creating address";
    return;
  }

  const result = await submitOrder(
    route.params.course_id as string,
    route.params.order_uid as string,
    {
      card_token: selectedCardToken.value,
      payment_gateway: checkoutState.paymentGateway,
    }
  );
  // console.log(`${result.type}:`);
  if (result.type === "success") {
    // submitting.value = false;
    location.href = result.redirect_path;
    return;
  }
  if (
    result.type === "requires_action" ||
    result.type === "requires_confirmation"
  ) {
    // console.log("Stripe confirmCardPayment");
    // console.log(result.result);
    stripe.value
      ?.confirmCardPayment(result.result.stripe_payment_intent.client_secret)
      .then((resp) => {
        if (resp.error) {
          checkoutState.paymentError = resp.error.message ?? null;
        } else {
          location.href = result.redirect_path;
        }
        submitting.value = false;
      });
    return;
  }
  if (result.type === "requires_payment_method") {
    console.log(result.result);

    submitting.value = false;
    return;
  }
  if (result.type === "general_failure") {
    checkoutState.paymentError = result.result.message;
    submitting.value = false;
    return;
  }
  // debugger;
  submitting.value = false;
  // .catch((err) => {
  //   submitting.value = false;
  //   if (axios.isAxiosError(err) && err.response?.status === 422) {
  //     const error = err as AxiosError<{
  //       error: string;
  //     }>;
  //     checkoutState.paymentError = error.response?.data?.error || null;
  //   }
  // });
  // submitting.value = false;
}

// eslint-disable-next-line no-undef
loadStripe(gon.stripe_key, {
  locale: locale.value as StripeConstructorOptions["locale"],
}).then((s) => {
  stripe.value = s;
});
</script>

<i18n lang="yaml">
en:
  booking: "Booking"
  back: "Back"
  first_name: "First name"
  last_name: "Last name"
  participant_information: Fill in the details of the course participant
  email: "Email"
  mobile_number: Mobile number
  next: "Next"
  payment: Payment
  monthly_billing: Monthly billing
  pay_with_credit_card: Pay with credit card
  address: Address
  postal_code: Postal code
  city: City
  country: Country
  agree_terms_label: I agree to the {link}.
  terms_and_conditions: Terms and conditions
  credit_card: Credit card
  invoice: Invoice
  pay_with_invoice: Pay via invoice (bank transfer)
  company_name: Company name
  contact_person: Contact person
  send_invoice: Send invoice
  add_new_card: Add new credit card
nb:
  booking: "Påmelding"
  back: "Tilbake"
  first_name: "Fornavn"
  last_name: "Etternavn"
  participant_information: Informasjon om kursdeltaker
  email: "E-post"
  mobile_number: Mobilnummer
  next: "Neste"
  payment: Betaling
  monthly_billing: Månedlig betaling
  pay_with_credit_card: Betal med kredittkort
  address: Adresse
  postal_code: Postnummer
  city: By
  country: Land
  agree_terms_label: Jeg godtar {link}.
  terms_and_conditions: Vilkår og betingelser
  credit_card: Kredittkort
  invoice: Faktura
  pay_with_invoice: Betal via faktura (bankoverføring)
  company_name: Bedriftsnavn
  contact_person: Kontaktperson
  send_invoice: Send faktura
  add_new_card: Legg til nytt kredittkort
uk:
  booking: "Бронювання"
  back: "Назад"
  first_name: "Ім'я"
  last_name: "Прізвище"
  participant_information: Заповніть дані про учасника курсу
  email: "Електронна пошта"
  mobile_number: Мобільний номер
  next: "Далі"
  payment: Оплата
  monthly_billing: Місячний розрахунок
  pay_with_credit_card: Оплатити кредитною карткою
  address: Адреса
  postal_code: Поштовий індекс
  city: Місто
  country: Країна
  agree_terms_label: Я погоджуюсь з {link}.
  terms_and_conditions: Умовами та положеннями
  credit_card: Кредитна картка
  invoice: Рахунок
  pay_with_invoice: Оплатити рахунком (банківський переказ)
  company_name: Назва компанії
  contact_person: Контактна особа
  send_invoice: Надіслати рахунок
  add_new_card: Додати нову кредитну картку
es:
  booking: "Inscripción"
  back: "Volver"
  first_name: "Nombre"
  last_name: "Apellido"
  participant_information: Rellene los datos del participante del curso
  email: "Correo electrónico"
  mobile_number: Número de teléfono móvil
  next: "Siguiente"
  payment: Pago
  monthly_billing: Facturación mensual
  pay_with_credit_card: Pagar con tarjeta de crédito
  address: Dirección
  postal_code: Código postal
  city: Ciudad
  country: País
  agree_terms_label: Estoy de acuerdo con los {link}.
  terms_and_conditions: Términos y condiciones
  credit_card: Tarjeta de crédito
  invoice: Factura
  pay_with_invoice: Pagar por factura (transferencia bancaria)
  company_name: Nombre de la empresa
  contact_person: Persona de contacto
  send_invoice: Enviar factura
  add_new_card: Agregar nueva tarjeta de crédito
</i18n>
